
import request from '@/utils/request'

/** 查询疫情数据列表 */
export function listHealth(query) {
    return request({
        url: '/system/health/list',
        method: 'get',
        params:query,
        headers: { isToken: false }
    })
}


export function batchHealth(data) {
    return request({
        url: '/system/health/batch',
        method: 'put',
        data: data
    })
}
