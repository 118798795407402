<template>
	<div>
		<div class="container">
			<!-- 背景 -->
			<img class="back-ground-image" src="@/assets/image/idx-yiqing-new.jpg" alt="" />
			<!-- 顶部 -->
			<div class="header">数字东泰</div>
			<!-- 返回 刷新 -->
			<rightNav></rightNav>
			<!-- 时间 -->
			<div class="time-box">
				<Date></Date>
			</div>
			<!-- top -->
			<div class="renkou-top">
				<div class="renkou-top-L">
					<!-- 涉境外回莞人员 -->
					<ShewaiHg></ShewaiHg>
				</div>
				<div class="renkou-top-R">
					<!-- 疫情防控数据展示 -->
					<YiqingFk></YiqingFk>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { listHealth, batchHealth } from '@/api/health';
import Date from '@/views/commonComponents/date.vue';
import ShewaiHg from '@/views/yiQing/components-new/shewaiHg.vue';
import YiqingFk from '@/views/yiQing/components-new/yiqingFk.vue';
import rightNav from '@/views/commonComponents/rightNav.vue';
export default {
	components: {
		Date,
		ShewaiHg,
		YiqingFk,
		rightNav,
	},
	data() {
		return {
			dialog: false,
			dialog_title: '修改疫情信息',
			tableData: [],
		};
	},
	created() {},
	methods: {
		onSubmit() {
			for (let i = 0; i < this.tableData.length; i++) {
				this.dealData(this.tableData[i]);
			}
			batchHealth(this.tableData).then((res) => {
				this.$message('修改成功');
				this.edit_dialog = true;
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			});
		},
		dealData(data) {
			data.total =
				parseInt(data.jcjjgl) +
				parseInt(data.jcjzgl) +
				parseInt(data.jjgl) +
				parseInt(data.jzgl) +
				parseInt(data.sqjkgl) +
				parseInt(data.zxlg);
		},
		// 修改table tr行的背景色
		tableStyle({ row, rowIndex }) {
			return 'background: none;';
		},
		// 修改table header的背景色
		tableHeaderColor({ row, column, rowIndex, columnIndex }) {
			return 'background: none;';
		},
	},
	mounted() {},
};
</script>

<style scoped lang="scss">
.container {
	width: 100%;
	height: auto;
	overflow: hidden;
	.back-ground-image {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
	}
	// 顶部
	.header {
		width: 100%;
		height: 10vh;
		line-height: 10vh;
		letter-spacing: 4vw;
		padding-left: 1vw;
		// background: linear-gradient(to top, #c0d9ff, #fff);
		background: linear-gradient(to top, #c0d9ff, #fff);
		-webkit-background-clip: text;
		color: transparent;
		font-size: 3vw;
		text-align: center;
		font-family: Microsoft YaHei;
		font-weight: bold;
		// color: #FFFFFF;
		text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
	}
	.time-box {
		width: 25vw;
		height: 7vh;
		// border: 1px solid teal;
		position: fixed;
		top: 7vh;
		right: 3vw;
		z-index: 99;
	}
	// top
	.renkou-top {
		width: 100vw;
		height: 51vh;
		// border: 1px solid tomato;
		.renkou-top-L {
			width: 42.6vw;
			height: 84vh;
			// border: 1px solid tan;
			margin-left: 2vw;
			float: left;
			position: relative;
		}
		.renkou-top-M {
			width: 41.5vw;
			height: 45vh;
			// border: 1px solid tan;
			margin-left: 4.3vw;
			margin-top: 6vh;
			float: left;
		}
		.renkou-top-R {
			width: 49vw;
			height: 78vh;
			float: right;
			margin-right: 2vw;
			// border: 1px solid tan;
			margin-top: 6vh;
		}
	}
	//   bottom
	.renkou-bottom {
		width: 96vw;
		height: 31vh;
		// border: 1px solid tomato;
		margin: 0 auto;
		margin-top: 5.7vh;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.renkou-bottom-L {
			width: 47.5%;
			height: 31vh;
			// border: 1px solid tomato;
			position: relative;
		}
		.renkou-bottom-R {
			width: 46.5%;
			height: 31vh;
			// border: 1px solid tomato;
			position: relative;
		}
	}
}
</style>
