<template>
    <div class="idx-middle-box3">
        <div class="idx-middle-1" @click="dialog_click()" >国内疫情区返莞数据</div>
        <div class="idx-middle-2" id="yiqing2a">
        </div>
        <Dialog
                :dialog="edit_dialog"
                dialogTitle="国内疫情区返莞数据"
                :dataList="list"
                prompt="*提示:每种类型的累计数据和目前数据之间用英文 ` , ` 分开;例现有集中隔离累计数据300,目前数据1,则在集中隔离列填写 ` 300,1 `!"
        >
        </Dialog>
    </div>
</template>

<script>
    import {listHealth} from "@/api/health";
    import Dialog from "@/components/dialog/dialog.vue";
    import { listData } from "@/api/dtdata.js";
    export default {
        components: {
            Dialog
        },
        data() {
            return {
                category: ['居家健康检测', '居家隔离', '集中隔离', '社区健康管理', '国内疫情区 \n返莞管控数据'],
                grandTotal: [],
                now:[],
                queryParams: {
                    dataModule: "sysj",
                    dataTyp: "sysj-gnyqqfgsj",
                },
                list: undefined,
                edit_dialog: false
            };
        },
        computed: {},
        created() {
            listData(this.queryParams).then(res => {
                this.list = res.rows;
                let l = ['居家健康检测', '居家隔离', '集中隔离', '社区健康管理', '国内疫情区返莞管控数据'];
                for(let i = 0; i < l.length; i++){
                    for(let j = 0; j < res.rows.length; j++){
                        if(l[i] == res.rows[j].dataName){
                            let s = res.rows[j].dataDetailed.split(',');
                            this.grandTotal[i] = parseInt(s[0]);
                            this.now[i] = parseInt(s[1]);
                            break;
                        }
                    }
                }
                this.yiqing2a();
            });

        },

        methods: {
            dialog_click() {
                if(!this.existSystem()){
                    return false;
                }
                this.edit_dialog = true;
            },
            // 疫情防控数据柱状图
            yiqing2a() {
                let myChart = this.$echarts.init(document.getElementById("yiqing2a"));
                // let app = {};
                // let option = null;
                let option = {
                    color:['#2363F1','#00CCFF'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: ['累计', '目前'],
                        bottom: '10px',
                        textStyle: {
                            color: '#FFFFFF'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '3%',
                        bottom: '8%',
                        containLabel: true
                    },
                    xAxis: {
                        show: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#0079FF'
                            }
                        },
                        axisLabel: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    yAxis: {
                        name: '2020年至今',
                        nameLocation: 'start',
                        nameTextStyle: {
                            color: '#FFFFFF'
                        },
                        nameGap: 10,
                        type: 'category',
                        axisLabel: {
                            color: '#FFFFFF'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#0079FF'
                            }
                        },
                        data: this.category
                    },
                    series: [
                        {
                            name: '累计',
                            type: 'bar',
                            barWidth: '30',
                            data: this.grandTotal,
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'right', //在上方显示
                                        textStyle: { //数值样式
                                            color: '#FFFFFF',
                                            fontSize: 14
                                        }
                                    }
                                }
                            }
                        },
                        {
                            name: '目前',
                            type: 'bar',
                            barWidth: '30',
                            data: this.now,
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'right', //在上方显示
                                        textStyle: { //数值样式
                                            color: '#FFFFFF',
                                            fontSize: 14
                                        }
                                    }
                                }
                            }
                        }
                    ]
                };
                myChart.setOption(option);
                // 使用刚指定的配置项和数据显示图表。
                /*myChart.setOption(option);*/
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            },
        },
        mounted() {
            //this.yiqing2a();
            //this.yiqing2b();
            // this.drawChart();
        },
    };
</script>

<style scoped lang="scss">
    .idx-middle-box3 {
        width: 100%;
        height: 78vh;
        overflow: hidden;
        //   border: 1px solid tan;
        .idx-middle-1 {
            width: 100%;
            height: 6vh;
            line-height: 6vh;
            text-align: center;
            font-size: 0.9vw;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffd901;
        }

        .idx-middle-2 {
            width: 100%;
            height: 72vh;
            overflow: hidden;
        }
    }
</style>
