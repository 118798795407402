<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1" @click="dialog_click()">涉境外回莞人员</div>
    <div class="idx-middle-2" id="dgwData"></div>
    <!--  -->
    <!-- <div class="idx-middle-3">
      <span>45</span>
      <span>已退休人数</span>
    </div> -->

    <Dialog
            :dialog="edit_dialog"
            dialogTitle="涉境外回莞人员"
            :dataList="list"
    >
    </Dialog>
  </div>
</template>

<script>
  import Dialog from "@/components/dialog/dialog.vue";
import { listHealth } from "@/api/health";
import { listData } from "@/api/dtdata.js";
export default {
  components: {
    Dialog
  },
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      queryParams: {
        dataModule: "sysj",
        dataTyp: "sysj-sjyhg",
      },
      queryParams1: {
        healthTyp: "涉境外回莞人员",
      },
      list: undefined,
      edit_dialog: false
    };
  },
  computed: {},
  created() {
    /*listHealth(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = response.rows[i].area;
        this.ydata[i].value = parseInt(response.rows[i].total);
        this.xdata[i] = response.rows[i].area;
        //console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      //    console.log(this.xdata);

      this.shijianFs();
    });*/

    listData(this.queryParams).then(res => {
      this.list = res.rows;
      for (let i = 0; i < res.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = res.rows[i].dataName;
        this.ydata[i].value = parseInt(res.rows[i].dataDetailed);
        this.xdata[i] = res.rows[i].dataName;
      }
      this.shijianFs();
    });
  },

  methods: {
    dialog_click() {
      if(!this.existSystem()){
        return false;
      }
      this.edit_dialog = true;
    },
    // 事件发生地
    shijianFs() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("dgwData"));
        let color = ["#2363F1", "#AF36DC", "#FF6D00", "#07AB2D"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          legend: {
            // 图例列表的布局朝向。 'horizontal'  'vertical'
            orient: "vertical",
            left: "40%",
            top: "65%",
            itemWidth: 12,
            itemHeight: 12,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 10,
              padding: 1,
            },
            formatter: function (name) {
              return "" + name;
            },
            data: this.xdata,
          },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: "30%",
              center: ["57%", "40%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    //   formatter: "{b} \n {c}人",
                    formatter: "{b} :\n {c} \n({d}%)",
                    fontSize: "10",
                    fontWeight: "bold",
                  },
                  labelLine: {
                    show: true,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("dgwData"));
        let color = ["#2363F1", "#AF36DC", "#FF6D00", "#07AB2D"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          legend: {
            // 图例列表的布局朝向。 'horizontal'  'vertical'
            orient: "horizontal",
            left: "23%",
            top: "75%",
            itemWidth: 40,
            itemHeight: 12,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 12,
              padding: 1,
            },
            formatter: function (name) {
              return "" + name;
            },
            data: this.xdata,
          },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: "40%",
              center: ["50%", "40%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    //   formatter: "{b} \n {c}人",
                    formatter: "{b} : {c} \n({d}%)",
                    fontSize: "12",
                    fontWeight: "bold",
                  },
                  labelLine: {
                    show: true,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    //this.shijianFs();
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  //   border: 1px solid tan;
  .idx-middle-1 {
    position: absolute;
    top: 0;
    left: 4.2vw;
    width: 17.3vw;
    height: 6vh;
    line-height: 7vh;
    text-align: center;
    font-size: 1vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .idx-middle-2 {
    width: 100%;
    margin-top: 7vh;
    height: 77vh;
    // border: 1px solid tan;
  }
  .idx-middle-3 {
    width: 23%;
    height: 8vh;
    line-height: 3vh;
    background: #01689b;
    position: absolute;
    top: 12vh;
    right: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    span {
      &:first-child {
        font-size: 1.2vw;
        font-family: hy;
        font-weight: bold;
        font-style: italic;
        color: #ffffff;
      }
    }
  }
}
</style>
